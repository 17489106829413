

import { Modal } from "bootstrap";
$(function () {

   if ($(".searchsuggestion").length) {
      $(".searchsuggestion").autocomplete({
         source: function (request, response) {
            getAutoSuggestion(request, response);
         }
      });
   }

   function getAutoSuggestion(request, response) {
      var suggesions = [];
      $.ajax({
         type: "get",
         url: $("#searchform").data("href"),
         dataType: "json",
         data: { q: request.term },
         success: function (res) {
            $.each(res.data, function (k, v) {
               suggesions.push(v);
            });
            return response(suggesions);
         }
      });
   }

   if ($("#videoplayer").length) {
      window.videoplayer = new Plyr('#videoplayer', app.player.defaultOptions);
   }
   if ($("#inline-player").length) {
      var inlinePlayer = new Plyr("#inline-player video", app.player.defaultOptions);


      $(document).on("click", ".play-hover .fa-play", function (e) {
         e.preventDefault();
         let element = $(this);
         let url = element.parent().data('url');
         if (!url) {
            return;
         }
         inlinePlayer.stop();
         let elplayer = element.parent();
         $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
            data: {
               _token: $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function () {
               element.removeAttr("class").addClass("spinner-border");
            }
         })
            .done(function (resp) {
               if (resp.status == true) {
                  let data = resp.data;
                  inlinePlayer.source = data.source;
                  $("#inline-player").hide().insertAfter(elplayer).fadeIn(1500);
                  inlinePlayer.play();
                  element.removeAttr("class").addClass("v-icon fa fa-play");
               }
            }).fail(function () {
               element.find(".play-hover").remove();
            });
      });
   }

   if ($(".v-options").length) {
      $(".v-options").on("click", ".like", function (e) {
         e.preventDefault();
         let data = {};
         $(this).toggleClass("active");
         if ($(".v-options .unlike").hasClass("active")) {
            $(".v-options .unlike").removeClass("active");
            storeLikes($(this).data("id"), "unlikes");
            data.unlike = true;
         } else {
            data.unlike = false;
         }
         if ($(this).hasClass("active")) {
            data.like = true;
         } else {
            data.like = false;
         }
         storeLikes($(this).data("id"), "likes");
         likeUnlikeApi($(this).data("href"), data);
      });

      $(".v-options").on("click", ".unlike", function (e) {
         e.preventDefault();
         let data = {};
         $(this).toggleClass("active");
         if ($(".v-options .like").hasClass("active")) {
            $(".v-options .like").removeClass("active");
            data.like = true;
            storeLikes($(this).data("id"), "likes");
         } else {
            data.like = false;
         }
         if ($(this).hasClass("active")) {
            data.unlike = true;
         } else {
            data.unlike = false;
         }
         storeLikes($(this).data("id"), "unlikes");
         likeUnlikeApi($(this).data("href"), data);
      });

      if (checkLikeUnlikes("likes")) {
         $(".v-options .like").addClass("active");
      } else if (checkLikeUnlikes("unlikes")) {
         $(".v-options .unlike").addClass("active");
      }
   }

   function checkLikeUnlikes(storage) {
      if (typeof (Storage) !== "undefined") {
         let id = $(".v-options").find(".like").data("id");
         let likes = localStorage.getItem(storage);
         if (likes) {
            let likesArr = JSON.parse(likes);
            if (likesArr.indexOf(id) > -1) {
               return true;
            }
         }
      }
      return false;
   }

   function likeUnlikeApi(url, postdata) {
      postdata._token = $('meta[name="csrf-token"]').attr('content');
      $.ajax({
         url: url,
         type: "post",
         dataType: "json",
         data: postdata,
      }).done(function (res) {
         if (res.status == true) {
            $(".v-options .like").find(".title").text(res.data.likes);
         }
         console.log("successfuly request");
      }).fail(function () {
         console.log("Request failed");
      });
   }

   function storeLikes(likeId, storage) {
      if (typeof (Storage) !== "undefined") {
         let newLikes = [];
         let likes = localStorage.getItem(storage);
         if (likes) {
            let oldLikes = JSON.parse(likes);
            let index = oldLikes.indexOf(likeId);
            if (index > -1) {
               oldLikes.splice(index, 1);
            } else {
               oldLikes.push(likeId);
            }
            newLikes = oldLikes;
         } else {
            newLikes.push(likeId);
         }
         console.log(JSON.stringify(newLikes));
         localStorage.setItem(storage, JSON.stringify(newLikes));
      } else {
         console.log("LocalStorage not suppored");
      }
   }

   $(".show-more-button").on("click", function (e) {
      e.preventDefault();
      $(this).toggleClass("active");
      $(".show-more").toggleClass("visible");
      if ($(".show-more").is(".visible")) {
         let el = $(".show-more"),
            curHeight = el.height(),
            autoHeight = el.css("height", "auto").height();
         el.height(curHeight).animate({ height: autoHeight, }, 400);
      } else {
         $(".show-more").animate({ height: "50", }, 400);
      }
   });


   //Videos Template
   function displayVideos(videos) {
      let tpl;
      let thumbWrapper = (app.player.isInlinePlayer == true) ? "vthumb-wrapper" : "thumb-wrapper";
      let thumbHover = (app.player.isInlinePlayer == true) ? "play-hover" : "thumb-hover";
      $.each(videos, function (i, v) {
         if (app.player.isInlinePlayer != true) {
            tpl = '<div class="col" style="display:none"><a href="' + v.url + '" class="card video">';
         } else {
            tpl = '<div class="col" style="display:none"><div class="card video">';
         }
         tpl += '<div class="' + thumbWrapper + '">'
            + '<div class="thumb">'
            + v.media.html
            + '</div>';
         if (v.duration != "" && v.duration != null) {
            tpl += '<span class="duration">' + v.duration + '</span>';
         }
         tpl += '<div class="' + thumbHover + '" data-url="' + v.links + '"><span class="v-icon fa fa-play" aria-hidden="true"></span></div>';
         tpl += '</div>'
            + '<div class="video-body">'
            + '<p class="title">';
         if (app.player.isInlinePlayer == true) {
            tpl += '<a href="' + v.url + '">' + v.title + '</a>';
         } else {
            tpl += v.title;
         }
         tpl += '</p>'
            + '</div>';
         if (app.player.isInlinePlayer != true) {
            tpl += '</a>'
         } else {
            tpl += "</div>";
         }
         tpl += '</div>';
         $("#pagination").prev().append(tpl);
      });
      $(".col:hidden").fadeIn();
   }

   //Categories Template
   function displayCategories(categories) {
      let tpl;
      $.each(categories, function (i, c) {
         tpl = '<div class="col" style="display:none">'
            + '<a href="' + c.url + '" class="card category">'
            + '<div class="thumb-wrapper">'
            + '<div class="thumb">'
            + c.media.html
            + '</div>';
         if (c.count != null && c.count != 0) {
            tpl += '<span class="right-top">' + c.count + ' ' + c.count_prefix + '</span>';
         }
         tpl += '<div class="category-body">'
            + '<p class="title">' + c.name + '</p>'
            + '</div>'
            + '</div>'
            + '</a>'
            + '</div>';
         $("#pagination").prev().append(tpl);
      });
      $(".col:hidden").fadeIn();
   }

   //Playlists Template
   function displayPlaylists(playlists) {
      let tpl;
      $.each(playlists, function (i, p) {
         tpl = '<div class="col" style="display:none">'
            + '<a href="' + p.url + '" class="card playlist">'
            + '<div class="thumb-wrapper">'
            + '<div class="thumb">'
            + p.media.html
            + '</div>'
            + '<span class="right-top">' + p.count + ' ' + p.count_prefix + '</span>'
            + '<div class="playlist-body">'
            + '<p class="title">' + p.name + '</p>'
            + '</div>'
            + '</div>'
            + '</a>'
            + '</div>';
         $("#pagination").prev().append(tpl);
      });
      $(".col:hidden").fadeIn();
   }

   function displayReleteds(videos) {
      let tpl;
      $.each(videos, function (i, v) {
         tpl = '<li class="item" style="display:none"><a href="' + v.url + '" class="d-flex">'
            + '<div class="align-self-start position-relative">'
            + v.media.html;
         if (v.duration != "" && v.duration != null) {
            tpl += '<span class="duration">' + v.duration + '</span>';
         }
         tpl += '</div>'
            + '<div class="ms-2">'
            + '<p class="title">' + v.title + '</p>'
            + '</div>'
            + '</a></li>';
         $("#pagination").prev().append(tpl);
      });
      $("li.item:hidden").fadeIn();
   }


   $("#loader").on("click", function (e) {
      e.preventDefault();
      LoadData();
   });

   function LoadData() {
      ApiPaging(function (resp) {
         if (resp.videos) {
            displayVideos(resp.videos);
         } else if (resp.categories) {
            displayCategories(resp.categories);
         } else if (resp.playlists) {
            displayPlaylists(resp.playlists);
         }
         else if (resp.reletedvideos) {
            displayReleteds(resp.reletedvideos);
         } else {
            console.log('No Data found');
         }
      });
   }

   //Api Pagging
   var isLastPage = false;
   var isLoading = false;
   var loaderStyle = $("#loader").attr("data-type");
   function showloading(status) {
      if (status == true) {
         if (loaderStyle == "scroll") {
            isLoading = true;
            $("#loader").show();
         } else {
            $("#loader").prepend('<span id="loading" class="spinner-grow spinner-grow-sm" aria-hidden="true"></span> ');
            $("#loader").attr('disabled', 'disabled');
         }
      } else {
         if (loaderStyle == "scroll") {
            isLoading = false;
            $("#loader").hide();
         } else {
            $("#loader #loading").remove();
            $("#loader").removeAttr('disabled');
         }
      }
   }

   function ApiPaging(callback) {
      let nextPage = parseInt($('#pagination').attr("data-page")) + 1;
      let datahref = $("#pagination").attr("data-href");
      let reqUrl = datahref ? datahref : window.location.toString();
      $.ajax({
         url: reqUrl,
         type: "GET",
         dataType: "json",
         data: {
            page: nextPage
         },
         beforeSend: showloading(true)
      })
         .done(function (resp) {
            showloading(false);
            let totalPage = resp.data.pagination.totalPage;
            if (nextPage >= totalPage
            ) {
               isLastPage = true;
               $("#loader").remove();
            }
            $("#pagination").attr("data-page", nextPage);
            if (resp.status == true) {
               startPagination();
               callback(resp.data);
            }
         }).fail(function () {
            $("#loader").remove();
            console.log("API Fetch Error" + url);
         });
   }


   /**
    * if Pagination and 
    *  window and document width same
    */
   function startPagination() {
      if ($(document).height() == $(window).height()) {
         if (loaderStyle == "scroll") {
            if (!isLoading && !isLastPage) {
               setTimeout(() => {
                  LoadData();
               }, 400);
            }
         }
      }
   }

   /**
    * on scroll event
    */
   $(window).on("scroll", function () {
      if (loaderStyle == "scroll") {
         if ($(window).scrollTop() >= $(document).height() - $(window).height() - 100) {
            if (!isLoading && !isLastPage) {
               LoadData();
            }
         }
      }
   });

   /**
    * if pagination avaiable and document width and window width same;
    */
   startPagination();

   function sharePopup(u, t = "Share", x = 840, y = 600) {
      let uri = u.replace("(url)", window.location.href).replace("(title)", document.title);
      let n = screen.width / 2 - x / 2;
      let i = screen.height / 2 - y / 2;
      window.open(
         uri,
         "social",
         "width=" +
         x +
         ",height=" +
         y +
         ",menubar=0,toolbar=0,scrollbars=0,left=" +
         n +
         ",top=" +
         i
      );
   }



   $(document).on("click", ".share a, .down-list a, .openlink", function (e) {
      e.preventDefault();
      let link = $(this).data("href");
      if (!link) {
         return;
      }
      sharePopup(link);
   });


   if ($("#modalDialog").length) {

      let label;

      let modal = new Modal($("#modalDialog"), {
         backdrop: "static",
         keyboard: false,
      });

      $("#modalDialog").on("hidden.bs.modal", function (event) {
         if ($("#shares").length) {
            $("#shares").hide();
         }
         if ($("#downloads").length) {
            $("#downloads").hide();
         }
      });

      if ($(".downButton").length) {
         $(".downButton").on("click", function (e) {
            let tpl;
            e.preventDefault();
            let url = $(this).data("href");
            $.ajax({
               url: url,
               dataType: "json",
               type: "POST",
               data: {
                  _token: $('meta[name="csrf-token"]').attr('content')
               }
            }).done(function (res) {
               if (res.status == true) {
                  tpl = '';
                  $.each(res.data, function (k, v) {
                     tpl += '<a href="#" data-href="' + v.url + '"  class="btn btn-outline-primary" role="button">' + v.label + '</a>';
                  });
                  $(".down-list").html(tpl);
                  $("#downloads").show();
                  $("#shares").hide();
                  label = $(".down-list").data("lang");
                  $("#modalDialogLabel").html('<i class="fa fa-download" aria-hidden="true"></i> ' + label);
                  modal.show();
               } else {
                  console.log("Failed to request download");
               }

            }).fail(function () {
               console.error("Download failed");
            });

         });

      }


      if ($(".sharebutton").length) {
         $(".sharebutton").on("click", function (e) {
            e.preventDefault();
            if (navigator.share !== undefined) {
               //Web share API
               navigator
                  .share({
                     title: "Share",
                     text: document.title,
                     url: window.location.href,
                  })
                  .then(function () {
                     console.info("Shared successfully.");
                  })
                  .catch(function (error) {
                     console.error("Error in sharing: ", error);
                  });
            } else {
               $("#shares").show();
               $("#downloads").hide();
               label = $(".share").data("lang");
               $("#modalDialogLabel").html('<i class="fa fa-share" aria-hidden="true"></i> ' + label);
               modal.show();
            }
         });

      }

   }

});